define('ember-changeset/utils/merge-nested', ['exports', 'ember-changeset/utils/set-nested-property'], function (exports, _setNestedProperty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mergeNested /*:: <T> */;
  var keys = Object.keys;


  /**
   * Given an array of objects, merge their keys into a new object and
   * return the new object.
   *
   * This function merges using `setNestedProperty`.
   */
  function mergeNested() /*: Array<{ [string]: T }> */
  /*: { [string]: T } */{
    var finalObj = {};

    for (var _len = arguments.length, objects = Array(_len), _key = 0; _key < _len; _key++) {
      objects[_key] = arguments[_key];
    }

    objects.forEach(function (obj) {
      return keys(obj).forEach(function (key) {
        return (0, _setNestedProperty.default)(finalObj, key, obj[key]);
      });
    });

    return finalObj;
  }
});