define('ember-changeset/-private/relay', ['exports', 'ember-changeset/utils/is-relay'], function (exports, _isRelay) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.ObjectProxy.extend({
    /*::
    _super() {},
    notifyPropertyChange() {},
    _changedKeys: {},
    */

    /**
     * Internal descriptor for relay identification.
     */
    __relay__: _isRelay.RELAY,

    changeset: null,
    key: '',
    content: null,

    init: function init() {
      var r /*: RelayDef */ = this;
      r._super.apply(r, arguments);
      r._changedKeys = {};
      (false && !(!!Ember.get(this, 'changeset')) && Ember.assert('changeset must be present.', !!Ember.get(this, 'changeset')));
      (false && !(!!Ember.get(this, 'content')) && Ember.assert('content must be present.', !!Ember.get(this, 'content')));
      (false && !(!!Ember.get(this, 'key')) && Ember.assert('key must be present.', !!Ember.get(this, 'key')));
    },
    unknownProperty: function unknownProperty(key) {
      var r /*: RelayDef */ = this;
      if (!r.changeset) throw new Error('Relay has no changeset.');
      return r.changeset._valueFor(r.key + '.' + key);
    },
    setUnknownProperty: function setUnknownProperty(key, value) {
      var r /*: RelayDef */ = this;
      r._changedKeys[key] = null;
      if (!r.changeset) throw new Error('Relay has no changeset.');
      r.changeset._validateAndSet(r.key + '.' + key, value);
      r.notifyPropertyChange(key);
      return value;
    },
    rollback: function rollback() {
      var r /*: RelayDef */ = this;
      r._super.apply(r, arguments);
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.keys(r._changedKeys)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var k = _step.value;
          r.notifyPropertyChange(k);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      r._changedKeys = {};
    },
    destroy: function destroy() {
      var r /*: RelayDef */ = this;
      r._super.apply(r, arguments);
      r.changeset = null;
      r.content = null;
    },
    isEqual: function isEqual(other) {
      var r /*: RelayDef */ = this;
      var original = Ember.get(r, 'content');
      return r === other || original === other;
    }
  } /*: RelayDef */);
});