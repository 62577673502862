define("ember-cli-meta-tags/initializers/router-head-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function initialize() {
    if (true) {
      Ember.Router.reopen({
        headTags: Ember.inject.service(),
        init: function init() {
          var _this = this,
              _arguments = arguments;

          this._super.apply(this, arguments);

          this.on('routeDidChange', function () {
            Ember.get(_this, 'headTags').collectHeadTags();

            _this._super.apply(_this, _toConsumableArray(_arguments));
          });
        }
      });
    } else {
      Ember.Router.reopen({
        headTags: Ember.inject.service(),
        didTransition: function didTransition() {
          Ember.get(this, 'headTags').collectHeadTags();

          this._super.apply(this, arguments);
        }
      });
    }
  }

  var _default = {
    name: 'router-head-tags',
    after: 'head-tags',
    initialize: initialize
  };
  _exports.default = _default;
});