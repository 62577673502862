define("ember-one-way-select/templates/components/one-way-select/option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fLq6OXkM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"option\",true],[11,\"value\",[28,\"if\",[[24,[\"optionValuePath\"]],[28,\"get\",[[24,[\"option\"]],[24,[\"optionValuePath\"]]],null],[24,[\"option\"]]],null]],[11,\"selected\",[28,\"one-way-select/contains\",[[24,[\"selected\"]],[24,[\"option\"]],[24,[\"optionValuePath\"]],[24,[\"optionTargetPath\"]]],null]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"optionComponent\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[24,[\"optionComponent\"]]],[[\"option\",\"index\",\"groupIndex\"],[[24,[\"option\"]],[24,[\"index\"]],[24,[\"groupIndex\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"optionLabelPath\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"get\",[[24,[\"option\"]],[24,[\"optionLabelPath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"optionValuePath\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"get\",[[24,[\"option\"]],[24,[\"optionValuePath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"optionTargetPath\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"get\",[[24,[\"option\"]],[24,[\"optionTargetPath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"option\"],false],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-one-way-select/templates/components/one-way-select/option.hbs"
    }
  });

  _exports.default = _default;
});