define('ember-changeset/utils/validate-nested-obj', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateNestedObj;
  var keys = Object.keys;
  function validateNestedObj(label /*: string */
  , obj /*: Object */
  ) /*: void */{
    keys(obj).forEach(function (key) {
      key.split('.').forEach(function (_, i, allParts) {
        if (i < allParts.length - 1) {
          var path = allParts.slice(0, i + 1).join('.');
          var msg = 'Object "' + label + '" may not have keys that override each other.';
          (false && !(!(path in obj)) && Ember.assert(msg, !(path in obj)));
        }
      });
    });
  }
});