define("ember-router-scroll/index", ["exports", "ember-app-scheduler"], function (_exports, _emberAppScheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RouterScrollMixin = Ember.Mixin.create({
    service: Ember.inject.service('router-scroll'),
    isFastBoot: Ember.computed(function () {
      var fastboot = Ember.getOwner(this).lookup('service:fastboot');
      return fastboot ? fastboot.get('isFastBoot') : false;
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _emberAppScheduler.setupRouter)(this);

      if (true) {
        this.on('routeWillChange', function () {
          _this._routeWillChange();
        });
        this.on('routeDidChange', function (transition) {
          _this._routeDidChange(transition);
        });
      }
    },
    destroy: function destroy() {
      (0, _emberAppScheduler.reset)();

      this._super.apply(this, arguments);
    },

    /**
     * Updates the scroll position
     * @param {transition|transition[]} transition If before Ember 3.6, this will be an array of transitions, otherwise
     * it will be a single transition
     */
    updateScrollPosition: function updateScrollPosition(transition) {
      var url = Ember.get(this, 'currentURL');
      var hashElement = url ? document.getElementById(url.split('#').pop()) : null;

      if (Ember.get(this, 'service.isFirstLoad')) {
        Ember.get(this, 'service').unsetFirstLoad();
        return;
      }

      var scrollPosition;

      if (url && url.indexOf('#') > -1 && hashElement) {
        scrollPosition = {
          x: hashElement.offsetLeft,
          y: hashElement.offsetTop
        };
      } else {
        scrollPosition = Ember.get(this, 'service.position');
      }

      var preserveScrollPosition;

      if (true) {
        preserveScrollPosition = Ember.getWithDefault(transition, 'router.currentRouteInfos', []).some(function (routeInfo) {
          return Ember.get(routeInfo, 'route.controller.preserveScrollPosition');
        });
      } else {
        preserveScrollPosition = transition.some(function (t) {
          return Ember.get(t, 'handler.controller.preserveScrollPosition');
        });
      } // If `preserveScrollPosition` was not set on the controller, attempt fallback to `preserveScrollPosition` which was set on the router service.


      if (!preserveScrollPosition) {
        preserveScrollPosition = Ember.get(this, 'service.preserveScrollPosition');
      }

      if (!preserveScrollPosition) {
        var scrollElement = Ember.get(this, 'service.scrollElement');
        var targetElement = Ember.get(this, 'service.targetElement');

        if (targetElement) {
          window.scrollTo(scrollPosition.x, scrollPosition.y);
        } else if ('window' === scrollElement) {
          window.scrollTo(scrollPosition.x, scrollPosition.y);
        } else if ('#' === scrollElement.charAt(0)) {
          var element = document.getElementById(scrollElement.substring(1));

          if (element) {
            element.scrollLeft = scrollPosition.x;
            element.scrollTop = scrollPosition.y;
          }
        }
      }
    },
    _routeWillChange: function _routeWillChange() {
      if (Ember.get(this, 'isFastBoot')) {
        return;
      }

      Ember.get(this, 'service').update();
    },
    _routeDidChange: function _routeDidChange(transition) {
      var _this2 = this;

      if (Ember.get(this, 'isFastBoot')) {
        return;
      }

      var delayScrollTop = Ember.get(this, 'service.delayScrollTop');

      if (!delayScrollTop) {
        Ember.run.scheduleOnce('render', this, function () {
          return _this2.updateScrollPosition(transition);
        });
      } else {
        // as described in ember-app-scheduler, this addon can be used to delay rendering until after First Meaningful Paint.
        // If you loading your routes progressively, this may be a good option to delay scrollTop until the remaining DOM elements are painted.
        (0, _emberAppScheduler.whenRouteIdle)().then(function () {
          _this2.updateScrollPosition(transition);
        });
      }
    }
  });

  if (!true) {
    RouterScrollMixin = Ember.Mixin.create(RouterScrollMixin, {
      willTransition: function willTransition() {
        this._super.apply(this, arguments);

        this._routeWillChange();
      },
      didTransition: function didTransition(transitions) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        this._super.apply(this, [transitions].concat(args));

        this._routeDidChange(transitions);
      }
    });
  }

  var _default = RouterScrollMixin;
  _exports.default = _default;
});