define("ember-router-scroll/locations/router-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var uuid = function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0;
      var v = c === 'x' ? r : r & 3 | 8;
      return v.toString(16);
    });
  };

  var _default = Ember.HistoryLocation.extend({
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(false) && Ember.deprecate("Setting 'locationType' to 'router-scroll' in config/environment.js is deprecated, please change it to 'auto'. If you are overriding ember-router-scroll's implementation of \"pushState\" or \"replaceState\", then you can subclass and override a new location object from: import HistoryLocation from '@ember/routing/history-location';", false, {
        id: 'ember-router-scroll',
        until: '2.0.0'
      }));
    },
    pushState: function pushState(path) {
      var state = {
        path: path,
        uuid: uuid()
      };
      Ember.get(this, 'history').pushState(state, null, path);
      Ember.set(this, '_previousURL', this.getURL());
    },
    replaceState: function replaceState(path) {
      var state = {
        path: path,
        uuid: uuid()
      };
      Ember.get(this, 'history').replaceState(state, null, path);
      Ember.set(this, '_previousURL', this.getURL());
    }
  });

  _exports.default = _default;
});