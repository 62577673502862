define("ember-cli-head/templates/components/head-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+o+owTmf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"in-element\",[[24,[\"headElement\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"  \"],[7,\"meta\",true],[10,\"name\",\"ember-cli-head-start\"],[10,\"content\",\"\"],[8],[9],[1,[22,\"head-content\"],false],[7,\"meta\",true],[10,\"name\",\"ember-cli-head-end\"],[10,\"content\",\"\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-head/templates/components/head-layout.hbs"
    }
  });

  _exports.default = _default;
});