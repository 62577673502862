define('ember-changeset/utils/set-nested-property', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = setNestedProperty /*:: <T> */;
  var keys = Object.keys;


  /**
   * Set a property on an `obj`.
   *
   * This function deletes every key prefixed by `key` in `obj`, as well
   * as every key in the path leading up to `key`.
   */
  function setNestedProperty(obj /*: { [string]: T } */
  , key /*: string          */
  , value /*: T               */
  ) /*: T */{
    var objKeys = keys(obj);

    // Ensure object keys are in correct format.
    Ember.runInDebug(function () {
      objKeys.forEach(function (k) {
        var parts = k.split('.');
        var condition = parts.length === parts.filter(Boolean).length;
        (false && !(condition) && Ember.assert('Object must not have keys with empty parts.', condition));
      });
    });

    // Delete keys prefixed by `key`.
    objKeys.filter(function (k) {
      return k.indexOf(key + '.') === 0;
    }).forEach(function (k) {
      return delete obj[k];
    });

    // Delete keys in path leading up to `key`.
    key.split('.').slice(0, -1).forEach(function (_, i, allParts) {
      var key = allParts.slice(0, i + 1).join('.');
      delete obj[key];
    });

    // Set value and return.
    obj[key] = value;
    return value;
  }
});