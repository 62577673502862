define("ember-changeset/-private/err", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var Err =
  /*::
  value: mixed;
  validation: ValidationErr;
  */

  function Err(value /*: mixed */, validation /*: ValidationErr */) {
    _classCallCheck(this, Err);

    this.value = value;
    this.validation = validation;
  };

  exports.default = Err;
});